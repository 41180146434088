const configuration = {
  auth: {
    authority: process.env.REACT_APP_AUTHORITYLOG,
    clientId: process.env.REACT_APP_CLIENTID,
    knownAuthorities: [process.env.REACT_APP_AUTHORITY],
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
};

const loginRequest = {
  scopes: ["openid", "offline_access"],
};

const protectedResources = {
  api: {
    endpoint: window.location.origin + "/api",
    scopes: [process.env.REACT_APP_APISCOPE]
  }
};

export { configuration, loginRequest, protectedResources };
