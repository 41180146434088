import LoadingSpinner from "../Animation/LoadingSpinner";

export default function Button({ loading, disabled, className, children, ...newProps }) {
    return (<button
        disabled={loading || disabled}
        className={disabled ? "h-10 inline-flex items-center px-3 2xl:px-4 py-1 2xl:py-2 mx-2 2xl:mx-4 border border-transparent text-sm 2xl:text-md scale-50 2xl:scale-100 font-medium text-white bg-secondary rounded-md opacity-50 cursor-not-allowed" :
            (className ? className :
                (loading ? "h-10 inline-flex items-center px-3 2xl:px-4 py-1 2xl:py-2 ml-2 2xl:mx-4 border border-transparent text-sm 2xl:text-md scale-50 2xl:scale-100 font-medium text-white bg-secondary rounded-md hover:text-white hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary cursor-not-allowed" : "h-10 inline-flex items-center rounded-md px-3 2xl:px-4 py-1 2xl:py-2 ml-2 2xl:mx-4 border border-transparent text-sm 2xl:text-md scale-50 2xl:scale-100 font-medium text-white bg-secondary hover:text-white hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"))}
        {...newProps}
    >
        {loading ? <LoadingSpinner /> : children}
    </button>)
}