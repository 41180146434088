import { loginRequest, protectedResources } from "./config";
import { msalInstance } from "./AppProvider";
import { InteractionRequiredAuthError } from "@azure/msal-browser";


function verifyAuth(component) {
    if(msalInstance.getAllAccounts().length) {
        return component;
    }
}

function isUserAuthent() {
    return msalInstance.getAllAccounts() && msalInstance.getAllAccounts().length
}

function getUser() {
    if(msalInstance.getAllAccounts().length) {
        return msalInstance.getAllAccounts()[0];
    }
}

async function getAccessToken() {
    var account = msalInstance.getActiveAccount();
    try{
        const response = await msalInstance.acquireTokenSilent({
            scopes : protectedResources.api.scopes,
            account : account
        });
        return response.accessToken;
    } catch(error) {
        if (error instanceof InteractionRequiredAuthError) {
            return msalInstance
              .acquireTokenRedirect({
                scopes : protectedResources.api.scopes
            })
              .then((resp) => {
                return resp.accessToken;
              })
              .catch((err) => {
                return null;
              });
          } else {
            console.error(error);
          }
    }
}

async function getAccessTokenGraph() {
    var account = msalInstance.getActiveAccount();
    const response = await msalInstance.acquireTokenSilent({ ...loginRequest, account: account });
    return response.accessToken;
}

function logout() {
    msalInstance.logoutRedirect();
}

async function refreshAccessToken(){
    var account = msalInstance.getActiveAccount();
    const response = await msalInstance.acquireTokenRedirect({
        scopes : protectedResources.api.scopes,
        account : account,
        forceRefresh: true,
        prompt: 'none'
    });
    return response.accessToken;
}

export {
    verifyAuth,
    getUser,
    logout,
    isUserAuthent,
    getAccessToken,
    getAccessTokenGraph,
    refreshAccessToken
};