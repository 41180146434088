import React, { Suspense } from "react";

// language
import { I18nextProvider } from "react-i18next";
import i18n from "./locale/i18n";

import Router from "./utils/router/router";
import { BrowserRouter } from "react-router-dom";
import { LoadingComponent } from "./auth/components";
import { getUser } from "./utils/requests/user";
import ErrorPage from "./pages/Error";

export const UserContext = React.createContext();


class App extends React.Component {
  state = {
    user: null,
    error: null
  }

  componentDidMount() {
    getUser().then(res => {
      if (res.ok) {
        return res.json()
      } else {
        this.setState({ error: true })
      }
    }).catch(err => {
      this.setState({ error: true })
    }).then(u => {
      // if (u.refresh) {
      //   sessionStorage.clear()
      //   refreshAccessToken().then(ac => console.log(ac))
      // }
      this.setState({ user: u });
    });
  }

  render() {
    return <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <Suspense fallback={<LoadingComponent />}>
          {this.state.user ? (
            <UserContext.Provider value={this.state.user}>
                <Router />
            </UserContext.Provider>
          ) : (this.state.error ?
            <ErrorPage /> : <LoadingComponent />)}
        </Suspense>
      </BrowserRouter>
    </I18nextProvider >
      ;
  }
}

export default App;



