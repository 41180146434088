import React, { Fragment, useContext } from "react";
import { withTranslation } from "react-i18next";
import { logout } from "../../auth/verifyAuth";
import { ArrowDownIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { Popover, Transition } from "@headlessui/react";
import { UserContext} from '../../App';


function Aside({currentOf, setOf}) {
  const user = useContext(UserContext);

  return (
    <div className="pb-12 bg-white" style={{ height: '120px', backgroundImage: "url('assets/point jaune.png')", backgroundRepeat: 'repeat-x', backgroundSize: '1500px' }}>
      <div className="lg:px-8 lg:mx-auto ">
        <div className="flex items-center justify-between h-16 px-4">
          <div className=" flex justify-between text-white w-full">
            <div className="flex items-center" style={{ marginTop: '4rem' }}>
              <img
                className="h-20 w-auto -mt-1.5"
                src={process.env.PUBLIC_URL + "/logo192.png"}
                alt="Workflow"
              />
              <div className="text-xl md:text-3xl font-bold uppercase bg-white text-secondary font-ccca pl-8 pt-1  md:pr-0 w-screen md:w-fit" style={{ width: "30rem" }}>Mon espace numérique</div>
            </div>
          </div>
          <div className="flex mr-96 sm:mr-0 lg:mr-36 flex items-stretch">
            {user?.ofs?.length > 1 ?
              <div className="cursor-pointer mr-1 lg:mr-10 h-full">
                <Popover className="relative z-40 h-full">
                  <Popover.Button>
                    <div className="pt-8 pb-3 z-10 px-2 lg:px-6 text-center block flex lg:w-64 w-full h-full flex justify-between" style={{ backgroundColor: '#E6358A' }}>
                      <h3 className="text-md font-bold text-white font-ccca mr-2 hidden lg:block">{currentOf?.name}</h3>
                      <ArrowDownIcon className="h-6 w-6 text-white"/>
                    </div>
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute right-0 z-50 bg-gray-700 w-64" style={{ minWidth: '200px' }}>
                      {user?.ofs?.filter(of => of !== currentOf).map((of,key) => <div key={key} className="text-white border-t border-white mx-auto flex items-center bg-gray-700 py-2">
                        <h3 className="px-4 font-ccca-light text-white text-left w-full">
                          <p className="font-bold text-sm" onClick={()=>{setOf(of)}}>{of?.name}</p>
                        </h3>
                      </div>)}
                    </Popover.Panel>
                  </Transition>
                </Popover>
              </div>
              : null
            }
            <div className="cursor-pointer h-full">
              <Popover className="relative z-40">
                <Popover.Button>
                  <div className="bg-gray-700 w-fit pt-8 pb-3 z-10 px-2 hidden md:flex">
                    <UserCircleIcon className="h-12 w-12 text-white" />
                    <h3 className="text-lg font-bold text-white font-ccca">{user.firstName}{" "}{user.lastName}</h3>
                  </div>
                  <div className="block md:hidden bg-gray-700 flex w-fit pt-8 pb-3 z-10 px-2">
                    <UserCircleIcon className="h-6 w-6 text-white" />
                  </div>
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute right-0 xl:left-0 z-50 bg-gray-700" style={{ width: '310px' }}>
                    {user?.ofs?.length === 1 ? <div className="text-white border-t border-white mx-auto flex items-center bg-gray-500 h-16">
                      <h3 className="px-4 py-2 font-ccca-light text-white text-right xl:text-left w-full">
                        <p className="font-bold">Organisme de formation :</p>
                        <p className="font-extrabold">{user?.ofs[0]?.name}</p>
                      </h3>
                    </div> : null
                    }
                    <div className='text-white border-t border-white mx-auto flex items-center cursor-pointer' onClick={() => { window.location.href = process.env.REACT_APP_PWDFORGET_URL }}>
                      <div className='px-4 py-2 font-ccca text-white'>Modifier mon mot de passe</div>
                      <div className='border-l border-white p-1.5 absolute right-0'>
                        <img src='assets/fleche blanc.png' alt='arrow' className='w-7' />
                      </div>
                    </div>
                    <div className='text-white border-t border-white mx-auto flex items-center  cursor-pointer' onClick={() => {
                      window.location.href = process.env.REACT_APP_DEACTIVATEACCOUNT_URL;
                    }}>
                      <div className='px-4 py-2 font-ccca text-white  cursor-pointer' >Désactiver mon compte</div>
                      <div className='border-l border-white p-1.5 absolute right-0'>
                        <img src='assets/fleche blanc.png' alt='arrow' className='w-7' />
                      </div>
                    </div>
                    <div className='text-white border-t border-white mx-auto flex items-center' style={{ backgroundColor: "#E6358A" }} key="logout"
                      onClick={() => {
                        logout();
                      }}>
                      <div className='px-4 py-2 font-ccca text-white' >Se déconnecter</div>
                      <div className='border-l border-white p-1.5 absolute right-0'>
                        <img src='assets/fleche blanc.png' alt='arrow' className='w-7' />
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default withTranslation()(Aside);
