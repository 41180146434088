import ModalApp from "../components/Modal/ModalApp";

export default function Block({ darkMode, of, id }) {
    return <div className='mx-20 xl:px-32 xl:mx-4'>
        <div>
            <div className="py-8">
                {of?.authorizedApplications?.filter(a => a?.category?.id === id)?.length > 0 ?
                    <ul
                        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 2xl:flex gap-10 items-stretch relative z-20"
                    >
                        {of?.authorizedApplications?.filter(a => a?.category?.id === id).sort((a, b) => a.name < b.name ? -1 : 1).map((a, i) => (
                            <ModalApp app={a} key={a.code} index={i} darkMode={darkMode} />
                        ))}
                    </ul> : <div>Pas d'applications</div>}
                {/* {!darkMode && <img src='assets/CONE_JAUNE.png' alt='' className='xl:absolute right-0 h-40 -mt-32 hidden xl:block z-0' />} */}
            </div>
        </div>
    </div>
}