
import General from "../../pages/General";
import MyInformation from "../../pages/MyInformation";
// import * as Staff from "../../pages/staff/index";

const routes = [
    {
        path:"/info",
        module: "me",
        element : <MyInformation/>,
        exact : true,
        isMenuIcon: false
    },
    {
        path: "*",
        module : "general",
        element:<General/>,
        exact : false,
        isMenuIcon : false
    }
    // {
    //     path: "/staff/apps",
    //     module : "staff",
    //     element: <Staff.Applications />,
    //     exact : true,
    //     isMenuIcon : false,
    //     name : "StaffApps"
    // },
    // {
    //     path: "/staff/assignations",
    //     module : "staff",
    //     element: <Staff.Assignations/>,
    //     exact : true,
    //     isMenuIcon : false,
    //     name : "StaffAssign"
    // },
    // {
    //     path: "/staff/links",
    //     module : "staff",
    //     element: <Staff.Links/>,
    //     exact : true,
    //     isMenuIcon : false,
    //     name : "StaffLinks"
    // },
    // {
    //     path: "/staff/news",
    //     module : "staff",
    //     element: <Staff.News/>,
    //     exact : true,
    //     isMenuIcon : false,
    //     name : "StaffNews"
    // },
    // {
    //     path: "/staff",
    //     module : "staff",
    //     element: <Staff.General/>,
    //     exact : true,
    //     isMenuIcon : false,
    //     name : "Staff"
    // }
]

export default routes;