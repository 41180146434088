import { useState } from 'react'
import GlobalModal from './GlobalModal'

var colors = ["#E6358A", "#EA5C26", "#1DB3E8", "#03A885"]

export default function ModalApp({ app, index, darkMode = false }) {
    const [open, setOpen] = useState(false)

    return (
        <div>
            <div className="relative transform overflow-hidden h-full text-center transition-all cursor-pointer py-3 border " style={{ borderColor: colors[index % 4], color: colors[index % 4] }}>
                <div className='pb-14'>
                    <div className="mx-auto flex h-28 w-28 items-center justify-center rounded-full border bg-white" onClick={() => { window.open(app.url, '_blank') }}>
                        <img
                            src={app.logoUrl}
                            alt={app.name + "_img"}
                            className="max-h-20"
                            style={{ maxWidth: "80px" }}
                        />
                    </div>
                    <div className="mt-4 flex justify-center">
                        <h3 className="text-lg font-semibold leading-6 font-ccca w-64" onClick={() => { window.open(app.url, '_blank') }}>
                            {app.name}
                        </h3>
                    </div>
                    <div className='absolute bottom-0 w-full'>
                        <div className='text-white border border-white mx-auto my-4 flex items-center relative' style={{ width: '186px', borderColor : colors[index % 4]  }} onClick={() => { setOpen(true) }}>
                            <div className='px-4 py-2 font-ccca ' style={{ color: colors[index % 4] }}>En savoir plus</div>
                            <div className='absolute right-0' style={{ backgroundColor:colors[index % 4] , padding:'5px' }}>
                                <img src='assets/fleche blanc.png' alt='arrow' className='w-7' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <GlobalModal open={open} setOpen={setOpen} app={app} color={colors[index % 4]} />
        </div>
    )
}