import React, { useEffect, useState, useContext } from "react"
import Aside from './Aside';
import routes from '../../utils/router/routes';
import { Outlet } from "react-router";
import { UserContext } from '../../App';

export const OfContext = React.createContext();

export default function Global() {
  const user = useContext(UserContext);
  const [currentOf, setOf] = useState()

  useEffect(()=>{
    if(user && user.ofs && user.ofs.length>0){
      setOf(user.ofs[0])
    }
  }, [user])

  return (
    <div className="min-h-screen  overflow-x-hidden w-full">
      <Aside navigation={routes.filter(r => r.isMenuIcon)} currentOf={currentOf} setOf={setOf} />
      {currentOf && <main>
        <div className="overflow-y-auto">
          <OfContext.Provider value={currentOf}>
            <Outlet />
          </OfContext.Provider>
        </div>
      </main>}
    </div>
  )
}
