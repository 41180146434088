import { useEffect, useState } from 'react';
import { getUser } from "../auth/verifyAuth";
import Button from '../components/Buttons/Button';

export default function MyInformation() {
    const [user, setUser] = useState({});

    useEffect(() => {
        setUser(getUser().idTokenClaims);
    }, [])

    return (
        <div className="w-full">
            <div className="bg-white shadow-sm rounded-lg p-6 pt-6 border">
                <div className="grid grid-cols-2 justify-between">
                    <div className="flex justify-end text-right font-bold text-2xl">
                        <div>
                            <div>{user.extension_ofaName}</div>
                            <div className='text-gray-500 text-xl font-medium'>{user.extension_site}</div>
                        </div>
                    </div>
                </div>
                <ul className="mt-2">
                    {user.given_name && <li><b>Prénom : </b> {user.given_name}</li>}
                    {user.family_name && <li><b>Nom : </b>{user.family_name}</li>}
                    {user.emails && <li><b>Email : </b>{user.emails[0]}</li>}
                    {user.jobTitle && <li><b>Titre : </b>{user.jobTitle}</li>}
                    {user.extension_companyName && <li><b>Nom de l'entreprise : </b>{user.extension_companyName}</li>}
                </ul>
            </div>
            <div className='flew justify-center'>
                <Button onClick={() => {
                    window.location.href = process.env.REACT_APP_DEACTIVATEACCOUNT_URL
                }}
                    className="h-10 inline-flex items-center rounded-md px-3 2xl:px-4 py-1 2xl:py-2 ml-2 2xl:mx-4 border border-transparent text-sm 2xl:text-md scale-50 2xl:scale-100 font-medium text-white bg-red-500 hover:text-white hover:bg-red-600"
                >Désactiver mon compte</Button>
            </div>
        </div>
    )
}
