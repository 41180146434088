import React from "react";

import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { configuration, loginRequest } from "./config";

import App from "../App";
import * as Components from "./components";

const msalInstance = new PublicClientApplication(configuration);

// Component
const AppProvider = () => (
  <MsalProvider instance={msalInstance}>
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      errorComponent={Components.ErrorComponent}
      loadingComponent={Components.LoadingComponent}
    >
      {() => {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
          msalInstance.setActiveAccount(accounts[0]);
        }

        return (
          <App
            userName={msalInstance.getAllAccounts().length ? msalInstance.getAllAccounts()[0].name : null}
          />
        );
      }}
    </MsalAuthenticationTemplate>
  </MsalProvider>
);

export { AppProvider, msalInstance };
