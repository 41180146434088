import { useContext, useEffect, useState } from 'react';
import { getRss } from '../utils/requests/blog';
import { UserContext } from '../App';
import { OfContext } from "../layout/Vertical/Global";
import ModalAppCommon from '../components/Modal/ModalAppCommon';
import Block from './Block';

export default function General() {
    const [news, setNews] = useState(null);
    const user = useContext(UserContext);
    const currentOf = useContext(OfContext);

    useEffect(() => {
        getRss().then(res => {
            if (res.ok) {
                return res.json()
            }
        }).then(rss => {
            setNews(rss.items.map(i => {
                return {
                    title: i.title.text,
                    href: i.links[0] ? i.links[0].uri : "",
                    category: i.categories.map(cat => { return { name: cat.name } }),
                    description: i.summary.text,
                    date: i.publishDate,
                    author: i.contributors
                }
            }))
        }).catch(err => {
            console.log(err)
        })
    }, [])

    return user ? (
        <div className="w-full mt-3">
            <div className="">
                {
                    currentOf.authorizedApplications?.reduce((res, a) => {
                        if ((res.length === 0 || !res.find(r => r.id === a?.category?.id)) && a?.category != null) {
                            res.push(a?.category)
                        }
                        return res
                    }, []).sort((cat1, cat2) => cat1.number > cat2.number ? 1 : -1).map((cat, index) => {
                        return index === currentOf.authorizedApplications?.reduce((res, a) => {
                            if ((res.length === 0 || !res.find(r => r.id === a?.category?.id)) && a?.category != null) {
                                res.push(a?.category)
                            }
                            return res
                        }, []).length - 1 ? <div className='bg-white'>
                            <div className='py-12'>
                                <div className="w-full py-5 xl:flex text-center bg-gray-300" >
                                    <h3 className=" px-5 lg:px-20 lg:mx-auto text-center xl:text-left xl:px-32 xl:mx-4">
                                        <div className="text-2xl font-medium text-black uppercase font-ccca">{cat.name}</div>
                                    </h3>
                                </div>
                                <div className="mx-20 xl:px-32 xl:mx-4 pt-8" key="commonapp">
                                    <ul className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 items-stretch gap-6'>
                                        {currentOf?.authorizedApplications?.filter(a => a?.category?.id === cat.id).sort((a, b) => a.name < b.name ? -1 : 1).map((a) => (
                                            <ModalAppCommon app={a} key={a.code} />
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div> : <div key={cat.id} >
                            <div className="w-full py-5 xl:flex text-center" style={{ backgroundColor: index % 2 === 0 ? "#FDEB1A" : null, backgroundImage: index % 2 === 0 ? null : "url('assets/noirrayé.png')", backgroundSize: '3500px' }}>
                                {index % 2 === 0 && <img src='assets/slash_blanc.png' alt='' className='absolute left-0 h-36 -mt-5 md:-ml-48 xl:-ml-8 z-0' />}
                                <h3 className=" px-5 lg:px-20 lg:mx-auto text-center xl:text-left xl:px-32 xl:mx-4 relative" >
                                <div className="text-2xl font-medium text-black uppercase font-ccca" style={{ color: index % 2 === 0 ? "black" : "white" }}>{cat.name}</div>
                                </h3>
                                {index === 0 && <img src='assets/right-img.png' alt='' className='absolute right-0 h-36 hidden xl:block' style={{ marginTop: '-120px' }} />}
                            </div>
                            {currentOf.authorizedApplications.length > 0 ? <Block key={cat.id} darkMode={false} of={currentOf} id={cat.id} /> : null}
                        </div>
                    })
                }
                {news && news.filter(ne => ne.category.find(cat => cat.name === "À la une")) ? <>
                    <div className="w-full py-5 mb-8 mt-6" style={{backgroundColor: "#313131"}}>
                        <h3 className="lg:mx-auto xl:px-32 xl:mx-4">
                            <div className="text-2xl font-medium text-white uppercase font-ccca text-center xl:text-left ">Dernières actualités</div>
                        </h3>
                    </div>
                    <div className='lg:overflow-hidden lg:h-80'>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 2xl:flex 2xl:flex-wrap gap-16 px-20 xl:px-36">
                            {news && news.filter(ne => ne.category.find(cat => cat.name === "À la une")).sort((a, b) => a.date > b.date).splice(0, 6).map((post, i) => {
                                var image = ''
                                // var description = post.description
                                try {
                                    if (post.description.includes('img')) {
                                        var list_image = post.description ? ((post.description.split('/>')[0]).split('http')) : ''

                                        if (list_image.length > 0) {
                                            image = list_image.find(x => x.includes('768w')).split(' 768w, ')[0]
                                            if (!image) {
                                                image = list_image.find(x => x.includes('1024')).split(' 1024w, ')[0]
                                            }
                                        }

                                        // description = post.description ? post.description.split('<p>')[1] : ''
                                    }
                                } catch (e) {

                                }

                                return <div key={post.title} className="overflow-hidden bg-gray-100 2xl:w-96" >
                                    <div className="h-36" style={{ backgroundImage: image ? "url( http" + image + ")" : "url('assets/standardpicblog.jpg')", backgroundPosition:"bottom", backgroundSize:"400px", backgroundRepeat:"no-repeat" }} />
                                    <div className="mb-1 flex flex-col pt-2 pb-4">
                                        <div className="flex-1 flex flex-col justify-between h-full relative pb-12 px-4">
                                            <div className="flex-1">
                                                <div target="_blank" className="block">
                                                    <p className="text-lg font-bold text-gray-500 h-20 overflow-y-hidden">{post.title}</p>
                                                    {/* <p className="text-base text-gray-500 text-clip h-12 overflow-hidden" dangerouslySetInnerHTML={{ __html: '<p>' + description }} /> */}
                                                </div>
                                            </div>
                                            <div className='absolute bottom-0'>
                                                <div className='text-white border border-white mx-auto flex items-center cursor-pointer relative' style={{ width: '212px', borderColor: "#9ca3af" }} onClick={() => { window.open(post.href, '_blank') }}>
                                                    <div className='px-4 py-1 font-ccca' style={{ color: "#9ca3af" }} >Accéder à l'article</div>
                                                    <div className='absolute right-0 z-0 border-l border-gray-400' style={{ padding: '2px' }}>
                                                        <img src='assets/fleche gray.png' alt='arrow' className='w-7' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div></div></> : null}
                <div className="flex items-center justify-center gap-3 mt-8 py-10">
                    <a href="https://www.instagram.com/3cabtp/" target="_blank"><img src={process.env.PUBLIC_URL + "assets/images/instagram.png"} className="h-10 w-10" alt="Instagram logo" /></a>
                    <a href="https://www.linkedin.com/company/3cabtp/" target="_blank"> <img src={process.env.PUBLIC_URL + "/assets/images/linkedin.png"} alt="LinkedIn logo" className="h-10 w-10" /></a>
                    <a href="https://www.facebook.com/3cabtp/" target="_blank"><img src={process.env.PUBLIC_URL + "/assets/images/facebook.png"} alt="Facebook logo" className="h-10 w-10" /></a>
                    <a href="https://www.x.com/3CABTP" target="_blank"> <img src={process.env.PUBLIC_URL + "/assets/images/x.png"} alt="X logo" className="h-10 w-10" /></a>
                    <a href="https://www.youtube.com/c/3CABTP" target="_blank"> <img src={process.env.PUBLIC_URL + "/assets/images/youtube.png"} alt="Youtube logo" className="h-10 w-10" /></a>
                </div>
            </div>
        </div>
    ) : null
}
