import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

function GlobalModal({ open, setOpen, app, color, darkMode=false }) {
    return <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOpen}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0 font-ccca-light ">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <Dialog.Panel className="bg-white relative overflow-hidden">
                            <div className="absolute right-0 top-0 pr-8 lg:pr-4 block z-40">
                                <button
                                    type="button"
                                    className="bg-gray-700 text-white py-7 px-4 hover:text-gray-200 focus:outline-none"
                                    onClick={() => setOpen(false)}
                                >
                                    <span className="sr-only">Close</span>
                                    <div className='font-ccca text-5xl'>X</div>
                                </button>
                            </div>
                            <div className='mt-3 pt-12 lg:pt-24 ml-12  pl-12 lg:pl-36 pb-3 pr-32' style={{ backgroundColor: color }}>
                                <h1 className={darkMode ? "text-3xl font-bold tracking-tight sm:text-4xl font-ccca text-black z-20 relative":"text-3xl font-bold tracking-tight sm:text-4xl font-ccca text-white z-20 relative"} >
                                    {app.name}
                                </h1>
                            </div>
                            <img src='assets/point jaune vertical.png' alt='' className='absolute left-0 hidden md:block ' style={{ width: '900px', marginLeft: '-130px', zIndex: '0', marginTop: '-380px' }} />
                            <div className="mx-auto max-w-2xl  lg:grid lg:max-w-7xl lg:grid-cols-2 gap-x-10 px-12 ml-4 pb-28 " >
                                {/* Product details */}
                                <div className='flex flex-col justify-between h-full bg-white' style={{ zIndex: '10', position: 'relative' }}>
                                    <div>
                                        <section aria-labelledby="information-heading " className="my-4 ">
                                            <div className="mt-2 space-y-6 " dangerouslySetInnerHTML={{ __html: app.description }} />
                                        </section>
                                    </div>
                                    <div className="">
                                        <div className='absolute left-0 bg-white'>
                                            <div className='text-white border border-white mx-auto my-4 flex items-center cursor-pointer bg-white relative' style={{ width: '225px', borderColor: darkMode ? "#6b7280":color }} onClick={() => { window.open(app.url, '_blank') }}>
                                                <div className='px-4 py-1 font-ccca' style={{ color: darkMode ? "#6b7280" :color }} >Accéder au site web</div>
                                                <div className={darkMode ? 'absolute right-0 z-0 border-l border-gray-500' :'absolute right-0 z-0'} style={{ backgroundColor: darkMode? null : color, padding: '2px' }}>
                                                    {darkMode ? <img src='assets/fleche gray.png' alt='arrow' className='w-7' /> :<img src='assets/fleche blanc.png' alt='arrow' className='w-7' />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Product image */}
                                <div className="lg:col-start-2 lg:row-span-2 lg:self-center hidden lg:block mt-2">
                                    <div >
                                        <img src={app.pictureUrl} alt={app.name + "_img"} className="max-h-96 w-auto" />
                                    </div>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition.Root>
}

export default GlobalModal