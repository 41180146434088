import { useState } from 'react'
import GlobalModal from './GlobalModal'


export default function ModalAppCommon({ app }) {
    const [open, setOpen] = useState(false)

    return (
        <div>
            <div className='h-full text-gray-500 border border-gray-500 my-1 flex relative cursor-pointer pr-10'>
                <div className='px-4 py-1 font-ccca text-gray-500 cursor-pointer flex items-center h-full' onClick={() => { window.open(app.url, '_blank') }}>{app.name}</div>
                <div className='border-l border-gray-500 p-1.5 flex items-center h-full absolute right-0' onClick={() => { setOpen(true) }}>
                    <img src='assets/fleche gray.png' alt='arrow' className='w-7' />
                </div>
            </div>
            <GlobalModal open={open} setOpen={setOpen} app={app} color="#FDEB1A" darkMode={true} />
        </div>
    )
}