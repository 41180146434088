import React from "react";
import { verifyAuth } from "../../auth/verifyAuth";
import routes from "./routes";
import { Outlet, useRoutes } from "react-router";

const Global = React.lazy(() =>
  import("../../layout/" + process.env.REACT_APP_ORIENTATION + "/Global")
);

function HandleAuthent() {
  return verifyAuth(<Outlet />);
}

function Router() {
  let el = useRoutes([
    {
      element: <HandleAuthent />,
      children: [
        {
          element: <Global />,
          children: routes
        }
      ]
    }
  ]);
  return el;
}

export default Router;